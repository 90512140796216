<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('dashboard.title')"
      :sub-title="$t('dashboard.subtitle')"
      btn
      variant="main"
      :btn-title="$t('common.orders_list')"
      classes="text-bold-15 text-white w-cus-sm-100"
      @btnAction="orderList"
    />

    <!-- <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div> -->
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        mt-md-0 mt-4
      "
    >
      <div class="d-flex flex-wrap flex-1 px-0">
        <b-col lg="12" md="12" class="px-0">
          <wameed-no-data
            v-if="getAllOrders && getAllOrders.length < 1"
            icon="nodata-icon"
            :title="$t('offers_page.no_data')"
            :sub-title="$t('offers_page.no_data_text')"
          />
        </b-col>
        <b-col
          v-for="(item, index) in getAllOrders"
          :key="index"
          lg="3"
          md="3"
          class="py-3"
        >
          <orders-card
            :id="item.id"
            :title="item.hr_job_title"
            :sub-title="item.created_at"
            :date="item.created_at"
            :employee-job-title="item.employee_job_title"
            :required-jobs-no="item.required_jobs_no"
            :submitted="item.submitted"
            :industry-preference="item.industry_preference.name"
          />
        </b-col>
      </div>

      <b-col
        v-if="getAllOrders && getAllOrders.length > 0"
        lg="12"
        md="12"
        class="px-0"
      >
        <wameed-pagination
          v-model="filterData.page"
          :page="filterData.page"
          :total-items="getTotalOrders.totalItems"
          :per_page="filterData.per_page"
          @changePage="changePage"
          @changeCurrentPage="changeCurrentPage"
        />
      </b-col>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import OrdersCard from '@/components/pages/orders/OrdersCard.vue';
// import WTables from '@/components/pages/applicants/WTables.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    OrdersCard,
  },
  data() {
    return {
      filterData: {
        status: '3',
        industry_preference: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: this.$i18n.t('common.project_rejected'), id: 0 },
            { title: this.$i18n.t('common.project_active'), id: 1 },
            { title: this.$i18n.t('common.project_before_paying'), id: 2 },
            { title: this.$i18n.t('common.project_under_review'), id: 3 },
            { title: this.$i18n.t('common.project_offer_selection'), id: 4 },
          ],
        },
        {
          title: 'industry_preference',
          id: 'id',
          name: 'name',
          key: 'industry_preference',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'fullName',
          label: this.$i18n.t('orders.package_name'),
          sortable: true,
        },
        {
          key: 'city',
          label: this.$i18n.t('orders.city'),
          sortable: true,
        },
        {
          key: 'startDate',
          label: this.$i18n.t('orders.start_date'),
          sortable: true,
        },
        {
          key: 'endDate',
          label: this.$i18n.t('orders.end_date'),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$i18n.t('orders.individual_count'),
          sortable: true,
        },
        {
          key: 'registered',
          label: this.$i18n.t('orders.registared'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('orders.status'),
          sortable: true,
        },
        { key: 'action', label: '' },
      ],
      status: [
        {
          0: 'project_rejected',
          1: 'project_active',
          2: 'project_before_paying',
          3: 'project_under_review',
          4: 'project_offer_selection',
          5: 'project_agreed',
          6: 'project_canceled',
          7: 'project_pending',
          8: 'project_finished_employee_not_paid',
          9: 'project_finished',
          10: 'project_close_request',
          11: 'project_sent_by_employee',
          12: 'project_request_cancel',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'info',
          3: 'icon',
          4: 'success',
          5: 'warning',
          6: 'danger',
          7: 'icon',
          8: 'warning',
          9: 'success',
          10: 'icon',
          11: 'success',
          12: 'warning',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllOrders: 'getAllOrders',
      getTotalOrders: 'getTotalOrders',
      getIndustryPreference: 'getIndustryPreference',
    }),
  },
  watch: {
    getIndustryPreference(data) {
      this.content[1].data = data.content;
    },
  },
  created() {
    this.loadData();
    this.loadIndustryPreference();
  },
  methods: {
    ...mapActions({
      loadOrders: 'loadOrders',
      loadIndustryPreference: 'loadIndustryPreference',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadOrders(this.filterData);
    },
    packageDetails(id) {
      this.$router.push({
        name: 'orders-list-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    applyFilter() {
      this.content.forEach((item) => {
        if (this.filterData.status === '') {
          this.filterData.status = '3';
        }
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        // this.filterData[item.key] = item.selectedData.toString();
        this.filterData = {
          status: '3',
          industry_preference: '',
          page: 1,
          per_page: 10,
          order_by: 'desc',
          search: '',
        };
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    orderList() {
      this.$router.push({
        name: 'orders-list',
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>
